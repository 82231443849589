<template>
  <footer class="bg-white" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="max-w-7xl mx-auto py-12 pb-8 px-4 sm:px-6 lg:px-8">
      <div class="grid md:grid-cols-2 gap-8">
        <router-link to="/">
          <img class="h-9 w-auto" src="@/assets/logo.svg" alt="Ultrack" />
        </router-link>
        <div class="grid sm:grid-cols-2 gap-8">
          <div>
            <h3 class="mb-3">Navigation</h3>
            <div class="space-y-1">
              <router-link
                v-for="route in navigation"
                :key="route.label"
                class="
                  block
                  with-underline
                  w-max
                  transition
                  hover:text-accent
                  capitalize
                "
                :to="route.path"
                >{{ route.label }}</router-link
              >
            </div>
          </div>
          <div>
            <h3 class="mb-3">Contact Us</h3>
            <div class="space-y-2">
              <a
                href="tel:+18008939506"
                class="block with-underline w-max hover:text-accent"
                ><strong>Phone:</strong>	 1-800-893-9506
              </a>
              <a
                href="mailto:info@ultrack.ca"
                class="block with-underline w-max hover:text-accent"
                ><strong>Email:</strong> info@ultrack.ca</a
              >
            </div>
			<div class="space-x-2 mt-5">
				<a href="https://www.facebook.com/UltrackSolutions" target="_blank" class="inline-block mr-2">
					<svg viewBox="0 0 24 24" width="24" height="24" stroke="#007b88" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>
				</a>
				<a href="http://www.twitter.com/ultracki" target="_blank" class="inline-block">
					<svg viewBox="0 0 24 24" width="24" height="24" stroke="#007b88" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>
				</a>
			</div>
          </div>
        </div>
      </div>
      <div
        class="
          mt-8
          pt-8
          border-t border-gray-200
          md:flex md:items-center md:justify-between
        "
      >
        <p class="text-base text-gray-400">&copy; Copyright {{ new Date().getFullYear() }} Ultrack</p>
      </div>
    </div>
  </footer>
</template>

<script>
const navigation = [
  {
    path: "/",
    label: "home",
  },
  {
    path: "/about",
    label: "about",
  },
  {
    path: "/features",
    label: "features",
  },
  {
    path: "/products",
    label: "products",
  },
  {
    path: "/investor-relations",
    label: "investor relations",
  },
  {
    path: "/contact",
    label: "contact",
  },
  {
    path: "/policy",
    label: "privacy policy",
  },
];
export default {
  setup() {
    return { navigation };
  },
};
</script>
