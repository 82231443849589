<template>
  <Popover
    class="fixed left-0 w-full top-0 z-20 transition"
    :class="!topOfPage && 'bg-white shadow'"
  >
    <div class="w-full bg-accent">
      <div
        class="max-w-7xl mx-auto px-4 sm:px-6 py-3 space-x-7 flex justify-end"
      >
        <a href="tel:1-800-893-9506" class="flex items-center text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
            />
          </svg>
          <span> 1-800-893-9506 </span>
        </a>
        <a href="mailto:info@ultrack.ca" class="flex items-center text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 mr-1"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
            />
            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
          </svg>
          <span> info@ultrack.ca </span>
        </a>
		  <Menu as="div" class="relative inline-block text-left">

		  <div>
			  <MenuButton class="flex items-center text-white">
				<svg viewBox="0 0 24 24" width="16" height="16" stroke="#ffffff" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1 h-4 w-4 mr-2"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
				Client Portal
			  </MenuButton>
			</div>

			<transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
			  <MenuItems class="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
				<div class="py-1">
				  <MenuItem v-slot="{ active }">
					<a href="http://serv1.ultrack.ca:8080/track/Track" target="_blank" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">ULT Solutions Ver.</a>
				  </MenuItem>
				  <MenuItem v-slot="{ active }">
					<a href="https://gps.ultrack.ca/new/" target="_blank" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">ULT Systems Ver.</a>
				  </MenuItem>
          <MenuItem v-slot="{ active }">
					<a href="https://myfleet.ultrack.ca/" target="_blank" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">My Fleet</a>
				  </MenuItem>
				</div>
			  </MenuItems>
			</transition>
		  </Menu>
      </div>
    </div>
    <div class="max-w-7xl mx-auto px-4 sm:px-6">
      <div
        class="
          flex
          justify-between
          items-center
          py-6
          md:justify-start md:space-x-5
        "
      >
        <div class="flex justify-start lg:w-0 flex-1">
          <router-link to="/">
            <span class="sr-only">Ultrack</span>
            <div class="flex items-center">
              <img
                class="h-9 w-auto"
                :src="
                  topOfPage
                    ? 'https://parssak.github.io/ultrack-website/logo-white.svg'
                    : 'https://parssak.github.io/ultrack-website/logo.svg'
                "
                alt="Ultrack"
              />
            </div>
          </router-link>
        </div>
        <div class="-mr-2 -my-2 md:hidden">
          <PopoverButton
            class="
              rounded-md
              inline-flex
              items-center
              justify-center
              transition
            "
            :class="
              topOfPage
                ? 'menu-icon-wrapper-top hover:bg-white '
                : 'hover:bg-accent menu-icon-wrapper-regular'
            "
          >
            <span class="sr-only">Open menu</span>
            <MenuIcon
              class="h-6 w-6 flex-shrink-0 m-2 menu-icon"
              aria-hidden="true"
              :class="
                topOfPage ? 'text-white hover:text-accent ' : 'text-accent'
              "
            />
          </PopoverButton>
        </div>
        <PopoverGroup
          as="nav"
          class="hidden md:flex space-x-3 lg:space-x-5 xl:space-x-7"
        >
          <NavItem
            v-for="item in navItems"
            :path="item.path"
            :key="item.label"
            :dropdownItems="item.dropdownItems"
            :topOfPage="topOfPage"
          >
            {{ item.label }}
          </NavItem>
        </PopoverGroup>
      </div>
    </div>

    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <PopoverPanel
        focus
        class="
          absolute
          top-0
          inset-x-0
          z-10
          p-2
          transition
          transform
          origin-top-right
          md:hidden
        "
      >
        <div
          class="
            rounded-lg
            shadow-lg
            ring-1 ring-black ring-opacity-5
            bg-gray-200
            divide-y-2 divide-gray-50
          "
        >
          <div class="pt-5 pb-6 px-5">
            <div class="flex items-center justify-between">
              <router-link to="/">
                <img class="h-5 w-auto" src="@/assets/logo.svg" alt="Ultrack" />
              </router-link>
              <div class="-mr-2">
                <PopoverButton
                  class="
                    rounded-md
                    p-2
                    inline-flex
                    items-center
                    justify-center
                    transition
                    hover:bg-accent-lightest
                  "
                >
                  <span class="sr-only">Close menu</span>
                  <XIcon class="h-6 w-6 text-accent" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <div class="mt-6">
              <nav class="grid gap-y-8">
                <router-link
                  v-for="item in navItems"
                  :key="item.label"
                  :to="item.path"
                  class="nav-item-mobile"
                >
                  <span class="nav-item-mobile__text">{{ item.label }}</span>
                </router-link>
              </nav>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
  <div
    :class="this.$route.fullPath === '/' && 'hidden'"
    style="margin-bottom: 7.5rem"
  />
</template>

<script>
import NavItem from "@/components/nav/NavItem.vue";
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Menu, MenuButton, MenuItem, MenuItems,
} from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline";

const navItems = [
  {
    path: "/",
    label: "home",
  },
  {
    path: "/about",
    label: "about",
  },
  {
    path: "/features",
    label: "features",
  },
  {
    path: "/products",
    label: "products",
  },
    {
    path: "/store",
    //path: "//store.ultrack.ca/prestashop/en",
    label: "Store",
  },
  {
    path: "/contact",
    label: "contact",
  },
];
export default {
  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel, 
	Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    MenuIcon,
    XIcon,
    NavItem,
  },
  props: {
    topOfPage: Boolean,
  },
  setup() {
    return { navItems };
  },
};
</script>

<style>
.menu-icon-wrapper-top:hover .menu-icon {
  color: rgb(0, 123, 136);
}

.menu-icon-wrapper-regular:hover .menu-icon {
  color: white;
}
</style>
