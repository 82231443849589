<template>
  <Nav :topOfPage="topOfPage" />
  <router-view />
  <Footer />
</template>

<script>
import Footer from "@/components/base/Footer.vue";
import Nav from "@/components/nav/Nav.vue";

export default {
  components: { Nav, Footer },
  data() {
    return {
      topOfPage: true,
    };
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  watch: {
    $route() {
      console.debug("route changed", this.$route);
      this.topOfPage = this.$route.fullPath === "/";
    },
  },
  methods: {
    handleScroll() {
      if (this.$route.fullPath !== "/") {
        this.topOfPage = false;
        return;
      }
      if (window.pageYOffset > 20) {
        if (this.topOfPage) this.topOfPage = false;
      } else {
        if (!this.topOfPage) this.topOfPage = true;
      }
    },
  },
};
</script>
