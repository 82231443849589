import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("@/views/home"),
  },
  {
    path: "/about",
    component: () => import("@/views/about"),
  },
  {
    path: "/features",
    component: () => import("@/views/features"),
  },
  {
    path: "/products",
    component: () => import("@/views/products"),
  },
  {
    path: "/products/eld",
    component: () => import("@/views/products/eld"),
  },
  {
    path: "/blog",
    component: () => import("@/views/blog"),
  },
  {
    path: "/blog/details",
    component: () => import("@/views/blog/details"),
  },
  {
    path: "/investor-relations",
    component: () => import("@/views/investor-relations"),
  },
  {
    path: "/contact",
    component: () => import("@/views/contact"),
  },
  {
    path: "/policy",
    component: () => import("@/views/policy"),
  },
  {
    path: "/store1", beforeEach: (to, from) => {
      console.log('Attempting to redirect...');
      location.href = 'https://store.ultrack.ca/prestashop/en';
    }
  },
  {
    path: "/store", component: () => {
      window.location.href = 'http://store.ultrack.ca/prestashop/en/';
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { left: 0, top: 0 };
  },
});

export default router;
